import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import applications from "./modules/applications";
import users from "./modules/users";
import constants from "./modules/constants";
import chats from "./modules/chats";
import countries from "@/static/countries";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        REGEX_EMAIL:
            /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,

        REGEX_PHONE_NUMBER: /^[0-9]{9,15}$/,
        REGEX_PHONE_COUNTRY_CODE: /^\+[0-9\-]{1,7}$/,

        REGEX_ZIP_CODE_PL: /^[0-9]{2}-[0-9]{3}$/,
        REGEX_ZIP_CODE_GENERAL: /^[A-Za-z0-9_-\s\/]{3,15}$/,

        REGEX_TAX_NUMBER_GENERAL: /^[A-Za-z]{2}[0-9A-Za-z\-\/]{3,29}$/,

        REGEX_MONGO_ID: /^[a-f\d]{24}$/i,

        REGEX_PASSWORD_RESET_TOKEN: /^[A-Fa-f0-9]{128}$/,

        REGEX_SHA512: /^[a-fA-F0-9]{128}$/,

        REGEX_IS_MONEY:
            /^([^.,0-9]*)?((([1-9][0-9]{0,2})( [0-9]{3})*)|([1-9][0-9]*)|0)((\.|,)[0-9]{1,2})?([^.,0-9]*)?$/,

        REGEX_VALID_FORMAT_DATE: /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/,

        MAX_SAFE_INTEGER: 9007199254740991,

        API_BASE_URL: process.env.VUE_APP_API_BASE_URL,
        API_BASE_PATH: process.env.VUE_APP_API_BASE_PATH,
        SELF_URL: process.env.VUE_APP_SELF_URL,

        USER_ROLE_LABELS: {
            user: "Użytkownik",
            account_manager: "Opiekun Klienta",
            lawyer: "Prawnik",
            admin: "Administrator"
        },
        USER_ROLE_COLORS: {
            user: "secondary",
            account_manager: "#8e44ad",
            lawyer: "#003F22",
            admin: "primary"
        },

        USER_STATUSES_LABELS: {
            preregistered: "Wstępnie utworzone",
            unconfirmed: "Niezweryfikowane",
            active: "Aktywne",
            blocked: "Zablokowane",
            suspended: "Zawieszone"
        },
        USER_STATUSES_COLORS: {
            preregistered: "secondary",
            unconfirmed: "#2c3e50",
            active: "success",
            blocked: "error",
            suspended: "grey"
        },

        PAYMENT_STATUSES: {
            CREATED: "created",
            INITIATED: "initiated",
            PENDING: "pending",
            PAID: "paid",
            REFUNDED: "refunded"
        },
        PAYMENT_STATUSES_COLORS: {
            created: "secondary",
            initiated: "accent",
            pending: "primary",
            paid: "success",
            refunded: "grey"
        },
        PAYMENT_STATUSES_LABELS: {
            created: "Utworzona",
            initiated: "Zainicjowana",
            pending: "Oczekująca",
            paid: "Opłacona",
            refunded: "Zwrócona"
        },

        NOTIFICATION_CODES: Object.freeze({
            APP_CUSTOM_DOCUMENT_CREATED: "app_custom_document_created",
            APP_DOCUMENT_FILE_UPLOADED: "app_document_file_uploaded",
            APP_STATUS_CHANGE: "app_status_change",
            APP_DOCUMENTS_EXPORTED: "app_documents_exported"
        }),

        storage: {},
        messages: [],

        app_booted: false,
        initial_layout_set: false,

        pluralization_messages: {
            minutes: ["0 minut", "1 minuta", "{n} minuty", "{n} minut"],
            hours: ["", "1 godzina", "{n} godziny", "{n} godzin"],
            days: ["", "1 dzień", "{n} dni", "{n} dni"]
        },

        providers: [
            {
                value: "PRAVNA",
                logo: "/static/imgs/pravna-logo.svg"
            },
            {
                value: "PFOZ",
                logo: "/static/imgs/pfoz-logo.svg"
            }
        ]
    },

    getters: {
        PESEL_VALIDATOR: () => pesel => {
            let full_year = pesel.slice(0, 2);
            let month = parseInt(pesel.slice(2, 4), 10);
            if (month > 80) {
                full_year = `18${full_year}`;
                month -= 80;
            } else if (month > 60) {
                full_year = `22${full_year}`;
                month -= 60;
            } else if (month > 40) {
                full_year = `21${full_year}`;
                month -= 40;
            } else if (month > 20) {
                full_year = `20${full_year}`;
                month -= 20;
            } else {
                full_year = `19${full_year}`;
            }
            // Add leading zero to month if needed
            if (month < 10) {
                month = `0${month}`;
            }
            // Check date validity
            const date = new Date(`${full_year}-${month}-${pesel.slice(4, 6)}`);
            if (date == "Invalid Date") {
                // console.log("id");
                return false;
            }
            if (
                date.getFullYear() != full_year ||
                ("0" + (date.getMonth() + 1)).slice(-2) != month ||
                ("0" + date.getDate()).slice(-2) != pesel.slice(4, 6)
            ) {
                // console.log("id2");
                return false;
            }

            // Calculate last digit by mulitplying with odd one-digit numbers except 5
            let checksum = 0;
            let multiplier = 1;
            for (let i = 0; i < pesel.length - 1; i++) {
                checksum += (parseInt(pesel[i], 10) * multiplier) % 10;
                multiplier += 2;
                if (multiplier > 10) {
                    multiplier = 1;
                } else if (multiplier === 5) {
                    multiplier += 2;
                }
            }
            checksum = 10 - (checksum % 10);
            return checksum === parseInt(pesel[10], 10);
        },
        PASSWORD_VALIDATOR: () => password => {
            let valid = true;
            if (password.length < 8) valid = false;
            else if (password.length > 63) valid = false;
            else if (!/[a-ząćęłńóśżźàâæçéèêëîïôœùûüÿöäß]+/.test(password)) valid = false;
            else if (!/[A-ZĄĆĘŁŃÓŚŻŹÀÂÆÇÉÈÊËÎÏÔŒÙÛÜŸÖÄẞ]+/.test(password)) valid = false;
            else if (!/[0-9]+/.test(password)) valid = false;
            return valid;
        },
        YYYYMMDD_DATE_VALIDATOR: () => d => {
            if (!d) return false;

            let valid = true;

            // 1. walidacja na bazową zgodność formatu
            if (!/^[1-9]\d{3}-((0[1-9])|(1[0-2]))-([0-2][0-9]|3[0-1])$/.test(d)) valid = false;

            // 2. dodatkowa walidacja na ilość dni w miesiącu
            const [year, month, day] = d.split("-");
            if (day === "00" || day > new Date(year, month, 0).getDate()) valid = false;

            return valid;
        },
        PHONE_NUMBER_VALIDATOR: () => num => {
            let valid = true;

            // 1. numer musi istnieć
            if (num === undefined || num === "") valid = "To pole jest wymagane";
            // 2. numer musi mieć 9-15 znaków długości
            else if (num.length < 9 || num.length > 15)
                valid = "Numer musi mieć 9-15 znaków długości";
            // 3. numer może składać się tylko z określonych znaków
            else if (!/^\+?[0-9 -]{9,14}$/.test(num))
                valid = "Proszę podać prawidłowy numer telefonu";
            // 4. numer musi się składać z przynajmniej 9 cyfr
            else if (num.replace(/[^0-9]/g, "").length < 9)
                valid = "Numer telefonu musi składać się przynajmniej z 9 cyfr";

            return valid;
        },

        VALIDATOR_TAX_NUMBER_PL: () => value => {
            if (/^[0-9]{10}$/.test(value) && value != "0000000000") {
                // NIP PL
                const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];
                const NIP = value.split("");
                let SUM = 0;
                for (let nnn = 0; nnn < 9; nnn++) SUM += parseInt(NIP[nnn]) * weights[nnn];
                if (SUM % 11 != NIP[9]) return false;

                return true;
            } else {
                // invalid NIP
                return false;
            }
        },
        getTaxNumberLocale: (state, getters) => tax_no => {
            if (getters.VALIDATOR_NIP_PL(tax_no)) return "PL";
            else if (state.REGEX_TAX_NUMBER_GENERAL.test(tax_no)) {
                return tax_no.slice(0, 2).toUpperCase();
            }
            return false;
        },

        getBirthDateFromPESEL: (state, getters) => pesel => {
            if (!getters.PESEL_VALIDATOR(pesel)) return "";
            let yp = pesel.slice(0, 2);
            let mp = pesel.slice(2, 4);
            let dp = pesel.slice(4, 6);

            const mpfc = mp.slice(0, 1);
            if (mpfc == "0" || mpfc == "1") {
                // 1900-1999 - nie robimy nic
                yp = "19" + yp;
            } else if (mpfc == "2" || mpfc == "3") {
                // 2000-2099
                mp = (mpfc - 2).toString() + mp.slice(1, 2);
                yp = "20" + yp;
            } else if (mpfc == "4" || mpfc == "5") {
                // 2100-2199
                mp = (mpfc - 4).toString() + mp.slice(1, 2);
                yp = "21" + yp;
            } else if (mpfc == "6" || mpfc == "7") {
                // 2200-2299
                mp = (mpfc - 6).toString() + mp.slice(1, 2);
                yp = "22" + yp;
            } else if (mpfc == "8" || mpfc == "9") {
                // 2200-2299
                mp = (mpfc - 8).toString() + mp.slice(1, 2);
                yp = "18" + yp;
            }

            return `${yp}-${mp}-${dp}`;
        },
        getVatRateLabel: () => vat_rate => {
            if (vat_rate === -1) return "zw.";
            return vat_rate.toString() + "%";
        },

        displayGeneralAddress: () => address => {
            if (
                !address ||
                address.street == "" ||
                address.house_no == "" ||
                address.zip_code == "" ||
                address.city == ""
            ) {
                return "-";
            }
            return `${address.street} ${address.house_no}${
                address.apartment_no ? "/" + address.apartment_no : ""
            }, ${address.zip_code} ${address.city}, ${
                countries.find(item => item.alpha2 == address.country)?.name?.pl || "-"
            }`;
        },

        pluralize: state => obj => {
            if (obj.n === undefined || isNaN(obj.n)) return "INVALID_N_PROPERTY";

            function getChoiceIndex(choice) {
                if (choice === 0) return 0;
                if (choice === 1) return 1;

                const teen = choice > 10 && choice < 20;
                const endsWithTwoThreeOrFour = choice % 10 >= 2 && choice % 10 <= 4;

                if (teen || (!teen && !endsWithTwoThreeOrFour)) return 3;
                return 2;
            }
            const x = getChoiceIndex(obj.n);

            if (obj.values && Array.isArray(obj.values)) {
                if (x < obj.values.length) return obj.values[x].replace("{n}", obj.n);
                else return "INVALID_VALUES_LENGTH";
            } else if (obj.message && typeof obj.message == "string") {
                if (
                    state?.pluralization_messages[obj.message] &&
                    Array.isArray(state.pluralization_messages[obj.message]) &&
                    state.pluralization_messages[obj.message].length > x
                )
                    return state.pluralization_messages[obj.message][x].replace("{n}", obj.n);
                else return "INVALID_MESSAGES_LENGTH";
            } else return "NO_TRANSLATION_DATA";
        },

        getStorageItem: state => key => {
            if (state.storage[key] !== undefined) return state.storage[key];
            return null;
        }
    },

    mutations: {
        setStorageItem(state, payload) {
            state.storage[payload.key] = payload.value;
        },
        removeStorageItem(state, key) {
            if (state.storage[key] !== undefined) {
                delete state.storage[key];
            }
        },

        addMessage(state, data) {
            state.messages.push({
                type: data.type,
                msg: data.msg,
                use_html: data.use_html || false,
                id: data.id
            });
        },
        removeMessage(state, id) {
            let index = state.messages.findIndex(i => i.id == id);
            if (index != -1) state.messages.splice(index, 1);
        }
    },

    actions: {
        addMessage({ commit }, data) {
            const id = "message_" + Date.now() + "_" + Math.floor(Math.random() * 10000);

            commit("addMessage", {
                ...data,
                id
            });

            if (!data.duration || data.duration != -1) {
                let dur = 3500;
                if (data.duration) dur = data.duration;

                setTimeout(() => {
                    commit("removeMessage", id);
                }, dur);
            }
        }
    },

    modules: {
        auth,
        applications,
        users,
        constants,
        chats
    }
});
